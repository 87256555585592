exports.components = {
  "component---src-page-templates-account-favorites-page-tsx": () => import("./../../../src/page-templates/AccountFavoritesPage.tsx" /* webpackChunkName: "component---src-page-templates-account-favorites-page-tsx" */),
  "component---src-page-templates-account-loyality-page-tsx": () => import("./../../../src/page-templates/AccountLoyalityPage.tsx" /* webpackChunkName: "component---src-page-templates-account-loyality-page-tsx" */),
  "component---src-page-templates-account-order-history-page-tsx": () => import("./../../../src/page-templates/AccountOrderHistoryPage.tsx" /* webpackChunkName: "component---src-page-templates-account-order-history-page-tsx" */),
  "component---src-page-templates-account-page-tsx": () => import("./../../../src/page-templates/AccountPage.tsx" /* webpackChunkName: "component---src-page-templates-account-page-tsx" */),
  "component---src-page-templates-authentification-page-tsx": () => import("./../../../src/page-templates/AuthentificationPage.tsx" /* webpackChunkName: "component---src-page-templates-authentification-page-tsx" */),
  "component---src-page-templates-blank-test-page-tsx": () => import("./../../../src/page-templates/BlankTestPage.tsx" /* webpackChunkName: "component---src-page-templates-blank-test-page-tsx" */),
  "component---src-page-templates-cart-page-tsx": () => import("./../../../src/page-templates/CartPage.tsx" /* webpackChunkName: "component---src-page-templates-cart-page-tsx" */),
  "component---src-page-templates-category-page-tsx": () => import("./../../../src/page-templates/CategoryPage.tsx" /* webpackChunkName: "component---src-page-templates-category-page-tsx" */),
  "component---src-page-templates-checkout-confirmation-page-tsx": () => import("./../../../src/page-templates/CheckoutConfirmationPage.tsx" /* webpackChunkName: "component---src-page-templates-checkout-confirmation-page-tsx" */),
  "component---src-page-templates-checkout-page-tsx": () => import("./../../../src/page-templates/CheckoutPage.tsx" /* webpackChunkName: "component---src-page-templates-checkout-page-tsx" */),
  "component---src-page-templates-checkout-pending-page-tsx": () => import("./../../../src/page-templates/CheckoutPendingPage.tsx" /* webpackChunkName: "component---src-page-templates-checkout-pending-page-tsx" */),
  "component---src-page-templates-home-page-tsx": () => import("./../../../src/page-templates/HomePage.tsx" /* webpackChunkName: "component---src-page-templates-home-page-tsx" */),
  "component---src-page-templates-navigation-test-page-tsx": () => import("./../../../src/page-templates/NavigationTestPage.tsx" /* webpackChunkName: "component---src-page-templates-navigation-test-page-tsx" */),
  "component---src-page-templates-news-page-tsx": () => import("./../../../src/page-templates/NewsPage.tsx" /* webpackChunkName: "component---src-page-templates-news-page-tsx" */),
  "component---src-page-templates-product-page-tsx": () => import("./../../../src/page-templates/ProductPage.tsx" /* webpackChunkName: "component---src-page-templates-product-page-tsx" */),
  "component---src-page-templates-store-locator-page-tsx": () => import("./../../../src/page-templates/StoreLocatorPage.tsx" /* webpackChunkName: "component---src-page-templates-store-locator-page-tsx" */),
  "component---src-page-templates-support-center-page-tsx": () => import("./../../../src/page-templates/SupportCenterPage.tsx" /* webpackChunkName: "component---src-page-templates-support-center-page-tsx" */),
  "component---src-page-templates-widget-page-tsx": () => import("./../../../src/page-templates/WidgetPage.tsx" /* webpackChunkName: "component---src-page-templates-widget-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

